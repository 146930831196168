<template>
	<v-container v-if="issueTypes.length > 0" class="pt-10 pb-8">
		<!-- FAB with subactions -->
		<v-speed-dial
			v-if="hasMultipleChoices"
			v-model="fab"
			v-bind:style="[getStyle]"
			fixed
			bottom
			right
			direction="top"
			transition="slide-y-reverse-transition"
		>
			<template v-slot:activator>
				<v-btn v-model="fab" fab color="secondary">
					<transition name="fade" mode="out-in">
						<v-icon v-if="fab">
							mdi-close
						</v-icon>
						<v-icon v-else>
							mdi-dots-vertical
						</v-icon>
					</transition>
				</v-btn>
			</template>

			<template v-for="(iType, index) in issueTypes">
				<v-btn
					v-if="iType.text"
					:key="index"
					:color="iType.color"
					@click="
						chosenIssueType = iType;
						overlay = true;
					"
					dark
					small
				>
					{{ iType.text }}
				</v-btn>

				<v-btn
					v-else
					:key="index"
					:color="iType.color"
					@click="
						chosenIssueType = iType;
						overlay = true;
					"
					fab
					dark
					small
				>
					<v-icon>{{ iType.icon }}</v-icon>
				</v-btn>
			</template>
		</v-speed-dial>

		<!-- FAB without subactions -->
		<v-btn
			v-else-if="issueTypes.length === 1 && issueTypes[0].text"
			@click="
				chosenIssueType = issueTypes[0];
				overlay = true;
			"
			v-bind:style="[getStyle]"
			color="secondary"
			fixed
			dark
			bottom
			right
		>
			{{ issueTypes[0].text }}
		</v-btn>

		<v-btn
			v-else-if="issueTypes.length === 1"
			@click="
				chosenIssueType = issueTypes[0];
				overlay = true;
			"
			v-bind:style="[getStyle]"
			color="secondary"
			fixed
			fab
			dark
			bottom
			right
		>
			<v-icon> {{ issueTypes[0].icon }} </v-icon>
		</v-btn>

		<v-dialog v-model="overlay" max-width="800px">
			<a-create-issue-card
				:issueType="chosenIssueType"
				@close="overlay = false"
				:title="title"
				:message="message"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import ACreateIssueCard from '@/components/CreateIssueCard';
import generalMixin from '@/mixins/generalMixin';

export default {
	components: {
		ACreateIssueCard,
	},
	mixins: [generalMixin],
	props: {
		issueTypes: {
			type: Array,
			default: () => [],
		},
		title: {
			type: Boolean,
		},
		message: {
			type: String,
			default: () => null,
		},
	},
	data: () => ({
		chosenIssueType: {},
		fab: false,
		overlay: false,
	}),
	computed: {
		hasMultipleChoices() {
			return this.issueTypes.length > 1;
		},
		getStyle() {
			const px = this.$_generalMixin_deviceIsIphoneXOrLater(navigator, screen)
				? '91px'
				: '72px';
			return { bottom: px };
		},
	},
};
</script>

<style></style>

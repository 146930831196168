<template>
	<v-list-item :to="to" two-line dense>
		<v-list-item-content class="pb-0 pl-3">
			<v-list-item-subtitle>{{ leftText }}</v-list-item-subtitle>
			<p v-if="textBelow">
				{{ textBelow }}
			</p>
		</v-list-item-content>

		<v-list-item-icon v-if="icon" dense>
			<v-icon color=" blue lighten-1">
				mdi-arrow-right
			</v-icon>
		</v-list-item-icon>
	</v-list-item>
</template>

<script>
export default {
	props: {
		leftText: {
			type: String,
			default: () => '',
		},
		textBelow: {
			type: String,
			default: () => '',
		},
		icon: {
			type: Boolean,
		},
		to: {
			type: Object,
			default: () => {},
		},
	},
};
</script>

<style></style>

<template>
	<v-row class="ma-0">
		<v-list-item :to="to">
			<v-col v-if="leftText" class="pt-0 pb-0">
				<v-list-item-content>
					<v-list-item-title>{{ leftText }}</v-list-item-title>
				</v-list-item-content>
			</v-col>

			<v-col v-if="rightText" class="pt-0 pb-0">
				<v-list-item-content>
					<v-list-item-title class="text-right">
						{{ rightText }}
					</v-list-item-title>
				</v-list-item-content>
			</v-col>

			<v-col v-if="icon" class="pt-0 pb-0">
				<v-list-item-content>
					<v-list-item-icon class="ma-0 flex-row-reverse">
						<v-icon v-if="showGoodIcon" color="green lighten-1">
							{{ goodIcon }}
						</v-icon>
						<v-icon v-else color="red lighten-1">
							{{ badIcon }}
						</v-icon>
					</v-list-item-icon>
				</v-list-item-content>
			</v-col>
		</v-list-item>
	</v-row>
</template>

<script>
export default {
	props: {
		leftText: {
			type: String,
			default: () => null,
		},
		rightText: {
			type: null,
			default: () => null,
		},
		icon: {
			type: Boolean,
		},
		showGoodIcon: {
			type: Boolean,
		},
		goodIcon: {
			type: String,
			default: () => 'mdi-check',
		},
		badIcon: {
			type: String,
			default: () => 'mdi-close',
		},
		to: {
			type: Object,
			default: () => {},
		},
	},
};
</script>

<style></style>

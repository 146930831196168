<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query :query="query" :update="data => data.missions">
			<template v-slot="{ result: { loading, data } }">
				<div v-if="data">
					<v-date-picker
						v-model="pickedDate"
						:events="missionsDates(data)"
						:event-color="date => eventColor(date)"
						:first-day-of-week="1"
						color="primary"
						full-width
						locale="sv-se"
						type="date"
						class="mb-4"
					/>

					<a-missions-by-date-card
						:date="pickedDate"
						:numberOfMissions="dateMissions(data)"
					/>
				</div>

				<a-loading v-else :loading="loading" type="date-picker" />
			</template>
		</apollo-query>

		<a-create-issue-fab icon="mdi-plus" description="Fråga om faktura" />
	</v-container>
</template>

<script>
import spacetime from 'spacetime';
import { MISSIONS } from '@/graphql';
import ACreateIssueFab from '@/components/CreateIssueFab';
import AMissionsByDateCard from '@/components/MissionsByDateCard';
import ALoading from '@/components/Loading';

export default {
	components: {
		ACreateIssueFab,
		AMissionsByDateCard,
		ALoading,
	},
	data: () => ({
		pickedDate: new Date().toISOString().slice(0, 10),
		today: spacetime.now('Europe/Stockholm').format('iso-short'),
		query: MISSIONS,
	}),
	computed: {},
	methods: {
		missionsDates(missions) {
			return missions.map(mission => mission.startDate);
		},
		dateMissions(missions) {
			const missionDates = this.missionsDates(missions);
			return missionDates.filter(date => date === this.pickedDate).length || 0;
		},
		eventColor(date) {
			switch (true) {
				case date === this.pickedDate:
					return 'white';
				case date < this.today:
					return 'grey';
				case date >= this.today:
					return 'primary';
				default:
					return '';
			}
		},
	},
};
</script>

<style></style>

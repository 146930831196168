<template>
	<apollo-query
		:query="query"
		:variables="{ input: { date } }"
		:skip="numberOfMissions <= 0"
	>
		<template v-slot="{ result: { loading, data } }">
			<v-card v-if="data && numberOfMissions > 0" class="mb-4">
				<v-list>
					<a-info-card-row-two-line
						v-for="(mission, index) in data.missions"
						:key="index"
						:to="{ name: 'booking', params: { id: mission.workorderlineId } }"
						:left-text="`${mission.startTime} - ${mission.endTime}`"
						:text-below="mission.workorderline.service.name"
						icon
					/>
				</v-list>
			</v-card>
			<v-card v-else-if="numberOfMissions <= 0">
				<v-list>
					<a-info-card-row
						left-text="Det finns inga bokningar på valt datum."
					/>
				</v-list>
			</v-card>
			<a-loading v-else :loading="loading" :type="skeleton" />
		</template>
	</apollo-query>
</template>

<script>
import ALoading from '@/components/Loading';
import AInfoCardRow from '@/components/InfoCardRow';
import AInfoCardRowTwoLine from '@/components/InfoCardRowTwoLine';
import { MISSIONS_BY_DATE } from '@/graphql';

export default {
	components: {
		ALoading,
		AInfoCardRow,
		AInfoCardRowTwoLine,
	},
	props: {
		date: {
			type: String,
			default: () => '',
		},
		numberOfMissions: {
			type: Number,
			default: () => 0,
		},
	},
	data: () => ({
		query: MISSIONS_BY_DATE,
	}),
	computed: {
		skeleton() {
			return `list-item-two-line@${this.numberOfMissions}`;
		},
	},
};
</script>

<style></style>
